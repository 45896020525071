<template>
  <fragment>
    <strong class="screen_out">이미지 및 PDF 뷰어</strong>
    <ul class="tab_viewer" role="tablist">
      <li>
        <a
          ref="image_tab"
          href="javascript:void(0);"
          class="link_tab"
          role="tab"
          :aria-selected="!pdfOn"
        >
          이미지
        </a>
      </li>
      <li>
        <a
          ref="pdf_tab"
          href="javascript:void(0);"
          class="link_tab"
          role="tab"
          :aria-selected="pdfOn"
        >
          PDF
        </a>
      </li>
    </ul>
    <div class="area_panel">
      <div class="panel" :class="{ panel_on: !pdfOn }">
        <!-- info_viewer -->
        <div id="infoImg" class="info_viewer">
          <!-- 좌우 버튼 -->
          <div class="btn_group" />

          <!-- 페이징 -->
          <div class="paging_num" />

          <!-- 회전 -->
          <a href="javascript:void(0);" class="btn_rotate">
            <span class="ico_account" />
          </a>

          <!-- 슬라이드 -->
          <div class="slide_viewer">
            <div v-for="(image, index) in imageList" :key="index" class="item_viewer">
              <img :src="image.url" alt="" />
            </div>
          </div>
        </div>
        <!-- // info_viewer -->
      </div>
      <div class="panel" :class="{ panel_on: pdfOn }">
        <!-- info_viewer -->
        <div id="infoPdf" class="info_viewer">
          <!-- 좌우 버튼 -->
          <div v-if="pdfList.length > 1" class="btn_group">
            <a href="javascript:void(0);" class="btn_prev" @click.prevent="pdfPrev"
              ><span class="ico_account">이전</span></a
            >
            <a href="javascript:void(0);" class="btn_next" @click.prevent="pdfNext"
              ><span class="ico_account">다음</span></a
            >
          </div>

          <!-- 페이징 -->
          <div class="paging_num">
            <span class="screen_out">현재 페이지</span
            ><em class="txt_current"> {{ currentPage + 1 }} </em
            ><span class="screen_out">전체 페이지</span> / {{ pdfList.length }}
          </div>

          <!-- 회전 -->
          <!-- <a href="javascript:void(0);" class="btn_rotate" @click.prevent="rotate += 90">
            <span class="ico_account"></span>
          </a>-->

          <!-- 슬라이드 -->
          <div class="slide_viewer">
            <pdf
              v-for="i in numPages"
              :key="i"
              :src="src"
              :page="i"
              :rotate="rotate"
              style="display: inline-block; width: 100%"
            />
            <!--  <div class="item_viewer" v-for="(image, index) in imageList" :key="index">
              <img :src="image.url" alt="">
            </div>-->
          </div>
        </div>
        <!-- // info_viewer -->
      </div>
    </div>
  </fragment>
</template>
<script>
import { Fragment } from "vue-fragment";
import pdf from "vue-pdf";
export default {
  name: "CommPopSlide",
  components: { Fragment, pdf },
  data() {
    return {
      fileList: {
        type: Array,
        default: [],
      },
      imageList: [],
      pdfList: [],
      rotate: 0,
      numPages: 5,
      src: {},
      currentPage: 0,
      renderComponent: true,
      flag: "",
      pdfOn: false,
    };
  },
  watch: {
    rotate() {
      if (this.rotate > 270) {
        this.rotate = 0;
      }
    },
  },
  created() {
    const obj = this;
    obj.fileList = JSON.parse(localStorage.imageList);
    obj.fileList.forEach((image, idx) => {
      if (obj.getExtensionOfFilename(image.name) === "pdf") {
        obj.pdfList.push(image);
      } else {
        obj.imageList.push(image);
      }
    });
  },
  mounted() {
    if (this.imageList.length <= 0) {
      this.pdfOn = true;
    }
    this.$nextTick(() => {
      this.renderPdf(this.currentPage);
    });
  },
  methods: {
    pdfNext() {
      if (this.currentPage < this.pdfList.length - 1) {
        this.src = null;
        this.renderComponent = false;
        this.renderComponent = true;
        this.currentPage = this.currentPage + 1;
        this.renderPdf(this.currentPage);
        this.flag = "next";
      }
    },
    pdfPrev() {
      if (this.currentPage > 0) {
        this.src = null;
        this.renderComponent = false;
        this.renderComponent = true;
        this.currentPage = this.currentPage - 1;
        this.renderPdf(this.currentPage);
        this.flag = "prev";
      }
    },
    renderPdf: function (idx) {
      const obj = this;
      const pdfObject = pdf;
      const _promise = function () {
        return new Promise(function (resolve) {
          obj.src = pdfObject.createLoadingTask(obj.pdfList[idx].url);
          setTimeout(function () {
            resolve(obj.src);
          }, 2200);
        });
      };
      _promise().then(
        function (result) {
          obj.numPages = result._pdfInfo.numPages;
        },
        function (error) {
          // 실패시
          console.error(error);
        },
      );
    },
    getExtensionOfFilename(filename) {
      let _fileLen = filename.length;
      let _lastDot = filename.lastIndexOf(".");

      // 확장자 명만 추출한 후 소문자로 변경
      let _fileExt = filename.substring(_lastDot + 1, _fileLen).toLowerCase();
      return _fileExt;
    },
  },
};
</script>
<style scoped>
#dkWrap.type_viewer {
  overflow-y: auto;
  padding-top: 0;
  height: 1080px;
  height: 100vh;
  background: #fff;
  box-sizing: border-box;
}
.type_viewer #mArticle {
  min-width: 1080px;
}
.tab_viewer {
  padding-top: 22px;
  border-bottom: 1px solid #d9d9d9;
}
.tab_viewer:after {
  display: block;
  clear: both;
  content: "";
}
.tab_viewer li {
  float: left;
}
.tab_viewer li + li {
  margin-left: 20px;
}
.tab_viewer .link_tab {
  display: block;
  position: relative;
  padding: 0 10px;
  font-size: 20px;
  line-height: 39px;
  color: #a9a9a9;
  text-decoration: none;
}
.tab_viewer [aria-selected="true"] {
  color: #1a1a1a;
  font-weight: 500;
}
.tab_viewer [aria-selected="true"]:after {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 3px;
  background: #ffcd00;
  content: "";
}

.area_panel .panel {
  display: none;
}
.area_panel .panel_on {
  display: block;
}

.info_viewer {
  position: relative;
  width: 600px;
  margin: 0 auto;
}

.btn_group {
  overflow: hidden;
  padding: 10px 0;
  font-size: 0;
  line-height: 0;
  text-align: center;
}
.btn_group [class*="btn_"] {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin: 0 35px;
  border: 1px solid #dadada;
  border-radius: 3px;
  vertical-align: top;
}
.btn_group [class*="btn_"] .ico_account {
  width: 5px;
  height: 8px;
  margin: 9px auto 0;
}
.btn_group .btn_prev .ico_account {
  background-position: -140px -70px;
}
.btn_group .btn_next .ico_account {
  background-position: -150px -70px;
}

.paging_num {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
}
.paging_num li {
  display: none;
  font-size: 20px;
  line-height: 28px;
  color: #a9a9a9;
}
.paging_num .slick-active {
  display: block;
}
.paging_num .txt_current {
  color: #1a1a1a;
}
.btn_rotate {
  position: absolute;
  top: 10px;
  right: 0;
  z-index: 10;
  width: 26px;
  height: 26px;
  border: 1px solid #dadada;
  border-radius: 3px;
} /* 2019-09-30 페이징 없을 경우 - 수정 */
.btn_rotate .ico_account {
  width: 15px;
  height: 18px;
  margin: 3px 0 0 7px;
  background-position: -160px -70px;
}
.slide_viewer {
  padding-top: 48px;
} /* 2019-09-30 페이징 없을 경우 - 추가 */
.paging_num + .btn_rotate + .slide_viewer {
  padding-top: 0;
} /* 2019-09-30 페이징 없을 경우 - 추가 */
.slide_viewer .item_viewer {
  overflow: hidden;
  position: relative;
  width: 600px;
  height: 600px;
  outline: 0;
}
.slide_viewer .item_viewer img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s cubic-bezier(0.14, 0.46, 0.6, 0.92);
}
.slide_viewer .item_viewer img.deg_0 {
  -webkit-transform: rotate(0);
  -moz-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}
.slide_viewer .item_viewer img.deg_90 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.slide_viewer .item_viewer img.deg_180 {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.slide_viewer .item_viewer img.deg_270 {
  -webkit-transform: rotate(-270deg);
  -moz-transform: rotate(-270deg);
  -ms-transform: rotate(-270deg);
  transform: rotate(-270deg);
}
</style>
